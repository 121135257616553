jQuery(document).ready(function(jQuery) {

    jQuery('.navbar').on('click', '.navbar-toggle-btn', function (e){
        e.preventDefault();

        jQuery(this).toggleClass('open');
        if(!jQuery(this).hasClass("open")) {
            jQuery('#sidebar').removeClass('active');
            jQuery('body').removeClass('menu-active');

        } else {
            jQuery('#sidebar').addClass('active');
            jQuery('body').addClass('menu-active');
        }
    });



});


$('.logo-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                variableWidth: false,
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 1201,
            settings: {
                variableWidth: false,
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 768,
            settings: {
                variableWidth: false,
                slidesToShow: 2,
            }
        }
        ]
});

$('.professionals-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    variableWidth: true,
    arrows: false,
    responsive: [
        {
            breakpoint:768,
            settings: {
                variableWidth: false,
                slidesToShow: 1,
            }
        }
    ]
});


function fixHeight(elem){
    var maxHeight = 0;
    $(elem).css('height','auto');
    $(elem).each(function(){
        if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
    });
    $(elem).height(maxHeight);
}

$(window).on('load resize', function(e) {
    var width = $(window).width();

    jQuery('.pricing-block').each(function () {
       var textBlock =  jQuery(this).find('.small-text-img-block').innerHeight();
       var listBlock =  jQuery(this).find('.pricing-features').innerHeight();
       var listBlockTitle =  jQuery(this).find('.pricing-features h4').innerHeight();
       var listBlockText =  jQuery(this).find('.pricing-features p').innerHeight();
       var div_padding = parseInt(jQuery(this).css('padding-bottom').replace('px',''));

       var tHeight = textBlock + div_padding;
       var ulMargin = listBlockTitle + listBlockText;

        if ( width >= 768 ) {
            jQuery(this).find('.pricing-features').css("height",'calc(100% - '+tHeight+'px)');
        }

        else {
            jQuery(this).find('.pricing-features').css("height","auto");
        }
    })

});

